import React from "react";
import SEO from "../components/seo";
import FunctionPageLayout from "../components/functionPageLayout";
import ArticleSquare from "../components/articleSquare";

const Blog = () => {
  return (
    <FunctionPageLayout title="Blog Questy" headline="Co u nas słychać?">
      <SEO
        title="Blog Questy"
        description="Blog Questy ✅ Co słychać w firmie Questy? • Questy - dostawcy oprogramowania dla biznesu"
        keywords={["blog questy"]}
      />
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >

      <ArticleSquare 
        title="Aplikacja do obsługi zleceń – podstawowe narzędzie każdego serwisanta" 
        link="/aplikacja-do-obslugi-zlecen/"    
        />
      <ArticleSquare 
        title="Automatyczny raport pracy serwisowej. Zarządzaj serwisem w&nbsp;jednym narzędziu." 
        link="/raport-pracy/"    
        />
      <ArticleSquare 
        title="Dlaczego warto łączyć moduł serwisowy z&nbsp;systemem CRM?" 
        link="/modul-serwisowy/"    
        />
      <ArticleSquare 
        title="Czym jest analiza przedwdrożeniowa? Dlaczego to must-have wdrożenia systemu dedykowanego?" 
        link="/analiza-przedwdrozeniowa/"    
        />
      <ArticleSquare 
        title="Wsparcie sprzedaży – jak CRM może pomóc menedżerom sprzedaży?" 
        link="/wsparcie-sprzedazy/"    
        />
      <ArticleSquare 
        title="Integracja systemów ERP z&nbsp;programem do obsługi zleceń" 
        link="/integracja-systemow-erp/"    
        />
       <ArticleSquare 
        title="Obsługa reklamacji online – droga do efektywności" 
        link="/obsluga-reklamacji-online/"    
        />
       <ArticleSquare 
        title="Wdrożenie panelu B2B kluczem do usprawnienia procesu sprzedaży" 
        link="/wdrozenie-panelu-b2b/"    
        />
      <ArticleSquare 
        title="Wykorzystaj program do tworzenia ofert handlowych w celu poprawy swoich działań sprzedażowych" 
        link="/program-do-tworzenia-ofert-handlowych/" 
        /> 
        <ArticleSquare 
        title="Porażki wdrożeń CRM: dlaczego się nie udaje?" 
        link="/wdrozenie-crm/" 
        />
      <ArticleSquare 
        title="CRM dla firmy IT: wsparcie sprzedaży i&nbsp;obsługi zleceń" 
        link="/crm-dla-firmy-it/" 
        />
        <ArticleSquare 
        title="Co to jest CRM? Wywiad z ekspertem [aktualizacja 2022]" 
        link="/co-to-jest-crm/" 
        />
         <ArticleSquare
          title="Dashboard managera – jeden rzut oka, by poznać sytuację całej firmy"
          link="/dashboard-managera/"
        />
         <ArticleSquare
          title="Promowanie produktów w&nbsp;platformie sprzedażowej B2B"
          link="/promowanie-b2b/"
        />
      <ArticleSquare
          title="Wsparcie procesu sprzedaży - system CRM"
          link="/proces-sprzedazy/"
        />
        <ArticleSquare
          title="Jak praca w systemie do obsługi zgłoszeń usprawnia działanie firmy?"
          link="/system-do-obslugi-zgłoszen-serwisowych/"
        />
        <ArticleSquare
          title="Zastępowalność pracowników, czyli jak poradzić sobie gdy pracownik 'znika'?"
          link="/zastepowalnosc-pracownikow/"
        />
        <ArticleSquare
          title="Jak zwiększyć efektywność dzięki automatyzacji sprzedaży?"
          link="/automatyzacja-sprzedazy/"
        />
        <ArticleSquare
          title="Cele sprzedażowe w&nbsp;CRM - jak je określać"
          link="/cele-sprzedazowe-smart/"
        />
        <ArticleSquare
          title="Sklep B2B - jak przygotować ofertę dla klienta hurtowego?"
          link="/sklep-b2b-jak-zaczac/"
        />
        <ArticleSquare
          title="Raportowanie sprzedaży"
          link="/raportowanie-sprzedazy/"
        />
        <ArticleSquare
          title="Narzędzia CRM online do pracy zdalnej"
          link="/narzedzia-crm-online/"
        />
        <ArticleSquare
          title="Sprawnie zarządzaj bazą urządzeń"
          link="/baza-urzadzen/"
        />
        <ArticleSquare
          title="7 problemów Twojego serwisu – jak je rozwiązać"
          link="/7-wyzwan-w-serwisie/"
        />
        <ArticleSquare
          title="Jak skutecznie zarządzać zleceniami cyklicznymi"
          link="/przeglady-cykliczne/"
        />
        <ArticleSquare
          title="Questy z certyfikatem ISO/IEC 27001"
          link="/certyfikat-iso-questy/"
        />
        <ArticleSquare
          title="18. urodziny Questy"
          link="/18-urodziny-questy/"
        />
        <ArticleSquare
          title="Integracja B2B z ERP: dlaczego warto?"
          link="/b2b-dla-erp/"
        />
        <ArticleSquare
          title="5 etapów wdrożenia systemu B2B w&nbsp;organizacji"
          link="/wdrozenie-platformy-b2b/"
        />
        <ArticleSquare
          title="System B2B - przeznaczenie i&nbsp;najważniejsze funkcje"
          link="/system-b2b-funkcje/"
        />
        <ArticleSquare
          title="Czym jest platforma B2B?"
          link="/czym-jest-platforma-b2b/"
        />
        <ArticleSquare
          title="Bezpieczeństwo informacji w firmie Questy - Norma ISO 27001"
          link="/wdrozenie-normy-iso/"
        />
        <ArticleSquare
          title="Integracja CRM z&nbsp;systemem handlowym"
          link="/integracja-crm-z-systemem-handlowym/"
        />
        <ArticleSquare
          title="Rozbudowa kanału sprzedaży pośredniej za pomocą systemu CRM"
          link="/rozbudowa-kanalu-sprzedazy-posredniej/"
        />
        <ArticleSquare
          title="Aplikacja CRM: ułatwiona praca w&nbsp;terenie"
          link="/aplikacja-crm-do-pracy-w-terenie/"
        />
        <ArticleSquare
          title="Zalety elastycznego systemu CRM"
          link="/zalety-elastycznego-crm/"
        />
        <ArticleSquare
          title="Ofertowanie w CRM: jak zarządzać tym procesem?"
          link="/ofertowanie-crm-jak-zarzadzac/"
        />
        <ArticleSquare
          title="Zarządzanie kampaniami, CRM i&nbsp;szanse sprzedaży"
          link="/zarzadzanie-kampaniami-crm-szanse-sprzedazy/"
        />
        <ArticleSquare
          title="System CRM a miękka windykacja"
          link="/system-crm-a-miekka-windykacja/"
        />
        <ArticleSquare
          title="Najpopularniejszych 5 funkcji w systemach CRM dla firm - RANKING 2020"
          link="/ranking-crm-dla-firm-funkcje/"
        />
        <ArticleSquare
          title="5 dobrych praktyk budowania relacji z&nbsp;klientami"
          link="/piec-dobrych-praktyk-budowania-relacji-z-klientami/"
        />
        <ArticleSquare
          title="Lejek sprzedaży - wsparcie dla działu handlowego"
          link="/lejek-sprzedazy-crm/"
        />
        <ArticleSquare
          title="Zarządzanie pracą zespołu marketingu w&nbsp;systemie CRM"
          link="/zarzadzanie-zespolem-marketingu-crm/"
        />
        <ArticleSquare
          title="Wdrażanie kultury pracy z CRM w&nbsp;organizacji"
          link="/wdrazanie-kultury-pracy-CRM/"
        />
        <ArticleSquare
          title="Zarządzanie rozproszonym zespołem sprzedażowym w&nbsp;czasie kryzysu"
          link="/zarzadzanie-zespolem-rozproszonym/"
        />
        <ArticleSquare title="Wigilia w Questy" link="/wigilia-w-questy/" />
        <ArticleSquare
          title="Warsztaty ”Recepta na rozwój biznesu” Justin Roff-Marsh i Łukasz Tadyszak"
          link="/warsztaty-sales-process-engineering/"
        />
        <ArticleSquare
          title="Skuteczne wdrożenie CRM w “Nowej Sprzedaży”"
          link="/skuteczne-wdrozenie-crm-czyli-jak-zarzadzic-zmiana-w-organizacji-w-nowej-sprzedazy/"
        />
        <ArticleSquare
          title="Co tak naprawdę ogranicza Twoją sprzedaż? - CEO Questy w “Nowej Sprzedaży” "
          link="/co-ogranicza-twoja-sprzedaz/"
        />
        <ArticleSquare
          title="Witamy w nowym biurze!"
          link="/nowe-biuro-questy/"
        />
        <ArticleSquare
          title="Questy na Targach E&nbsp;-&nbsp;Handlu 2018 w&nbsp;Krakowie"
          link="/questy-na-targach-e-handlu-2018-w-krakowie/"
        />
        <ArticleSquare
          title="Questy w „Law Business Quality” "
          link="/questy-w-law-business-quality/"
        />
        <ArticleSquare
          title="Questy na CEBIT 2014 w Hannowerze"
          link="/questy-na-cebit/"
        />
      </div>
    </FunctionPageLayout>
  );
};

export default Blog;

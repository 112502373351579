import React from "react";
import { Link } from "gatsby";
import * as styles from "./articleSquare.module.css";
import heroImage from "../images/Prosty-CRM-online-dla-malych-firm.jpg";

const ArticleSquare = ({ title, link }) => {
  return (
    <div className={styles.wrapper}>
      <Link
        style={{
          backgroundImage: `linear-gradient(rgba(134, 188, 42, 20), rgba(134, 188, 42, 20)), url(${heroImage})`,
        }}
        className={styles.block}
        to={link}
      >
        {title}
      </Link>
    </div>
  );
};

export default ArticleSquare;
